import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  pages:any=[
    {
      title:'Dashboard',
      url:'/app/dashboard',
      icon:'speedometer',
      color:'primary'
    },
    {
      title:'Settings',
      icon:'settings',
      color:'warning',
      moduleName:'settings',
      children:[
        {
          title:'Salary Incentive',
          url:'/app/settings/salary-incentives',
          icon:'cash',
          color:'danger',
          moduleName:'settings',
          actionName:"settings-salaryincentiveview"
        },
        {
          title:'Curling',
          url:'/app/settings/curling',
          icon:'cash',
          color:'medium',
          // moduleName:'settings',
          // actionName:"settings-salaryincentiveview"
        },
        {
          title:'Husk Purchase',
          url:'/app/settings/purchase',
          icon:'bag-add',
          color:'tertiary',
          // moduleName:'settings',
          // actionName:"settings-salaryincentiveview"
        },
        {
          title:'Meter Reading',
          url:'/app/settings/meter-reading',
          icon:'speedometer',
          color:'secondary',
          // moduleName:'settings',
          // actionName:"settings-salaryincentiveview"
        }
      ]
    },
    {
      title:'Day / Shift',
      url:'/app/day-open-close',
      icon:'today',
      color:'success',
      moduleName:'dayandshift',
      actionName:'dayandshift-view',
    },
    {
      title:'Work Allocation',
      url:'/app/work-allocation/view',
      icon:'today',
      color:'success'
    },
    {
      title:'Day Close',
      url:'/app/day-open-close/dayclose',
      icon:'today',
      color:'danger',
      moduleName:'dayandshift',
      actionName:'dayandshift-view',
    },
    {
      title:'Masters',
      icon:'home',
      color:'success',
      moduleName:'transporters',
      children:[
        {
          title:'Husk Bale Transporter',
          url:'/app/masters/bale-transporters',
          iconURL:'assets/icon/bale.svg',
          color:'primary',
          moduleName:'transporters',
          actionName:"transporters-view"
        },
        {
          title:'Loose Husk Transporter',
          url:'/app/masters/loose-husk-transporters',
          iconURL:'assets/icon/truck-moving-solid.svg',
          color:'danger',
        },{
          title:'Own Vehicle',
          url:'/app/masters/own-vehicles',
          iconURL:'assets/icon/truck-solid.svg',
          color:'secondary',
        },
        {
          title:'Contract Team Workers',
          url:'/app/masters/contract-team-workers',
          iconURL:'assets/icon/people-carry-solid.svg',
          color:'warning',
        },
        {
          title:'Yard',
          url:'/app/masters/yard',
          icon:'storefront',
          color:'tertiary',
        },
        {
          title:'Bunk',
          url:'/app/masters/petrol-bunk',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'success',
        },
        {
          title:'Machineries',
          url:'/app/masters/machineries',
          icon:'construct',
          color:'primary',
        },{
          title:'Pith Stock Yards',
          url:'/app/masters/pith-stock-yards',
          icon:'storefront',
          color:'secondary',
        },
      ]
    },
    {
      title:'Purchase',
      icon:'cart',
      color:'medium',
      moduleName:'purchases',
      url:'/app/purchases'
    },
    {
      title:'Purchase',
      icon:'cart',
      color:'secondary',
      moduleName:'purchases',
      children:[
        {
          title:'Bale Purchase',
          url:'/app/purchases/mysore-bale-delivery',
          icon:'cart',
          // iconURL:'assets/icon/people-carry-solid.svg',
          color:'primary',
          moduleName:'purchases',
          actionName:'purchases-add',
        },
        {
          title:'Fuel Purchase',
          url:'/app/purchases/fuel',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'success'
        }
      ]
    },{
      title:'Salary',
      icon:'cart',
      color:'secondary',
      moduleName:'purchases',
      children:[
        {
          title:'Weekly Wages',
          icon:'ellipse-outline',
          // iconURL:'assets/icon/people-carry-solid.svg',
          color:'primary',
          children:[
              {
                title:'Pre-Pay Report',
                url:'/app/salary/weekly-local-labours/pre-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Post-Pay Report',
                url:'/app/salary/weekly-local-labours/post-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Payment',
                url:'/app/salary/weekly-local-labours',
                icon:'remove-outline',
                color:'tertiary',
              },
            ]
        },
        {
          title:'Weekly OS Inc.',
          icon:'ellipse-outline',
          // iconURL:'assets/icon/people-carry-solid.svg',
          color:'primary',
          children:[
              {
                title:'Pre-Pay Report',
                url:'/app/salary/weekly-other-labours/pre-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Post-Pay Report',
                url:'/app/salary/weekly-other-labours/post-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Payment',
                url:'/app/salary/weekly-other-labours',
                icon:'remove-outline',
                color:'tertiary',
              },
            ]
        }
      ]
    },
    {
      title:'Payments',
      // url:'/app/purchases',
      icon:'cash',
      color:'danger',
      moduleName:'purchases',
      actionName:'purchases-payment',
      children:[
        {
          title:'Bale Transporters',
          icon:'checkmark',
          color:'secondary',
          moduleName:'purchases',
          actionName:'purchases-payment',
          children:[
            {
              title:'Hire Payment Approval',
              url:'/app/payments/bale-transporter-hire-payment-approval',
              icon:'checkmark',
              color:'primary',
              moduleName:'purchases',
              actionName:'purchases-payment',
            },
            {
              title:'Hire Payment',
              url:'/app/payments/bale-transporter-hire-payment',
              icon:'cash',
              color:'tertiary',
              moduleName:'purchases',
              actionName:'purchases-payment',
            },
            {
              title:'Deposit Payment',
              url:'/app/payments/bale-transporter-deposit-payment',
              icon:'card',
              color:'success',
              moduleName:'purchases',
              actionName:'purchases-payment',
            }
          ]
        },
        {
          title:'Bunk',
          url:'/app/payments/bunk-payment',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'wallet',
          color:'danger',
        },{
          title:'Weekly  Local Labours Payment',
          url:'/app/payments/weekly-local-labours',
          icon:'wallet',
          color:'warning',
        },{
          title:'Weekly  Other State Labours Payment',
          url:'/app/payments/weekly-other-state-labours',
          icon:'wallet',
          color:'warning',
        },{
          title:'Advance Payment',
          url:'/app/payments/local-labours-advance-payment',
          icon:'wallet',
          color:'warning',
        },{
          title:'Addition / Deduction Payment',
          url:'/app/payments/local-labours-adjustment-payment',
          icon:'wallet',
          color:'warning',
        },{
          title:'Incentive Payment',
          url:'/app/payments/incentive-payment',
          icon:'wallet',
          color:'warning',
        },{
          title:'Agent Payment',
          url:'/app/payments/agent-payment',
          icon:'wallet',
          color:'warning',
        },
      ]
    },
    {
      title:'Production',
      // url:'/app/purchases',
      icon:'construct',
      color:'primary',
      children:[
        {
          title:'Fuel Consumption',
          url:'/app/production/fuel-consumption',
          iconURL:'assets/icon/gas-pump-solid.svg',
          // icon:'checkmark',
          color:'success',
        },
        {
          title:'Beater Buster',
          url:'/app/production/beater-buster',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'checkmark',
          color:'tertiary',
        },{
          title:'Pith Bag Issue',
          url:'/app/production/pith-bag-issue',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'checkmark',
          color:'tertiary',
        },{
          title:'Pith Bag Transport',
          url:'/app/production/pith-pag-transport',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'checkmark',
          color:'tertiary',
        }
      ]
    },
    {
      title:'Reports',
      icon:'document-text',
      color:'medium',
      children:[
        {
          title:'Ledger Report',
          // url:'#',
          icon:'cash',
          color:'primary',
          children:[
              {
                title:'Bale Transporter Hire Ledger Report',
                url:'/app/reports/bale-transporter-hire-ledger',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Bale Transporter Deposit Leadger Report',
                url:'/app/reports/bale-transporter-deposit',
                icon:'card',
                color:'warning',
              },
          ]
        },
        {
          title:'Bale Delivery Report',
          url:'/app/reports/delivery',
          iconURL:'assets/icon/bale.svg',
          color:'medium',
        },
        {
          title:'Bunk',
          url:'/app/reports/bunk-leadger',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'tertiary',
        },
        {
          title:'Contract Team Workers',
          url:'/app/reports/contract-team-workers-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'people',
          color:'success',
        },
        {
          title:'Day Close',
          url:'/app/reports/day-close-report',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'calendar',
          color:'danger',
        },
        {
          title:'Attendance Report',
          icon:'document-text',
          color:'success',
          children:[
            {
              title:'Other State Labours Report',
              url:'/app/reports/attendance-other-state-labour',
              // iconURL:'assets/icon/gas-pump-solid.svg',
              icon:'document-text',
              color:'warning',
            },{
              title:'Local Labours Weekly Report',
              url:'/app/reports/attendance-weekly-local-labour',
              // iconURL:'assets/icon/gas-pump-solid.svg',
              icon:'document-text',
              color:'tertiary',
            }
          ]
        },
        {
          title:'Employee Incentive Ledger',
          url:'/app/reports/employee-incentive-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        },{
          title:'Agent Ledger',
          url:'/app/reports/agent-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        },{
          title:'Employee Salary Ledger',
          url:'/app/reports/employee-salary-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        },{
          title:'Pith Bag Issue',
          url:'/app/reports/pith-bag-issue',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        }
      ]
    },
    {
      title:'Employee',
      url:'/app/employees',
      icon:'people-circle',
      color:'secondary',
      moduleName:'employee',
      actionName:'employee-view'
    },
    {
      title:'Agent',
      url:'/app/masters/agents',
      icon:'people',
      color:'success',
      moduleName:'employeeagent',
      actionName:'employeeagent-view'
    },
    {
      title:'Roles',
      url:'/app/roles',
      icon:'people-circle',
      color:'tertiary',
      moduleName:'roles',
      actionName:'roles-view'
    },
    {
      title:'Users',
      url:'/app/users',
      icon:'people',
      color:'warning',
      moduleName:'users',
      actionName:'users-view'
    }
  ]
  buildVersion=environment.buildVersion;
  constructor(public auth:AuthService,private loadingController: LoadingController,
    private api:ApiService, private help:UxHelpersService, private navController:NavController,
    private storage:Storage) { }

  ngOnInit() {
    // if(environment.isProduction&&false){
    if(true){
      this.pages=[
        {
          title:'Dashboard',
          url:'/app/dashboard',
          icon:'speedometer',
          color:'primary'
        },
        {
          title:'Production',
          icon:'today',
          color:'danger',
          moduleName:['dayandshift','workallocation','beaterbuster'],
          isMulti:true,
          children:[
            {
              title:'Day / Shift',
              url:'/app/day-open-close',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'dayandshift',
              actionName:'dayandshift-view',
            },
            {
              title:'Work Allocation',
              url:'/app/work-allocation/view',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'workallocation',
              actionName:'workallocation-view',
            },
            {
              title:'Beater/Buster Entry',
              url:'/app/production/beater-buster',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'beaterbuster',
              actionName:'beaterbuster-view',
            },
            {
              title:'Pith Bag Issue',
              url:'/app/production/pith-bag-issue',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },
            {
              title:'Pith Bag Transport',
              url:'/app/production/pith-pag-transport',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            }
          ]
        },
        {
          title:'Purchase',
          icon:'cart',
          color:'secondary',
          url:'/app/purchases',
          moduleName:'purchases',
          actionName:'purchases-view',
        },
        {
          title:'Diesel',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'danger',
          moduleName:'dieselentry',
          children:[
            {
              title:'Diesel Purchase',
              url:'/app/purchases/fuel',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'dieselentry',
              actionName:'dieselentry-purchase',
            },
            {
              title:'Diesel Consumption',
              url:'/app/production/fuel-consumption',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'dieselentry',
              actionName:'dieselentry-consumption',
            }
          ]
        },
        {
          title:'Payments',
          icon:'cash',
          color:'primary',
          moduleName:'payments',
          children:[
            {
              title:'Bunk',
              url:'/app/payments/bunk-payment',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'payments',
              actionName:'payments-bunk',
            },
            {
              title:'Labour Advance',
              url:'/app/payments/local-labours-advance-payment',
              icon:'chevron-forward-outline',
              color:'warning',
              moduleName:'payments',
              actionName:'payments-labouradvance',
            },
            {
              title:'Labour Incentive',
              url:'/app/payments/incentive-payment',
              icon:'chevron-forward-outline',
              color:'warning',
              moduleName:'payments',
              actionName:'payments-labourincentive',
            },
            {
              title:'Agent Payment',
              url:'/app/payments/agent-payment',
              icon:'chevron-forward-outline',
              color:'primary',
              moduleName:'payments',
              actionName:'payments-agent',
            },{
              title:'Husk Supplier Payment',
              url:'/app/payments/husk-supplier-payments',
              icon:'chevron-forward-outline',
              color:'secondary',
              moduleName:'payments',
              actionName:'payments-supplier',
            },{
              title:'Contract Team Workers',
              url:'/app/payments/contract-team-workers',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'payments',
              actionName:'payments-contractteamworkers',
            },{
              title:'Husk Supplier Settlement',
              url:'/app/payments/husk-supplier-settlement-approval',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },{
              title:'Loose Husk Transporter',
              url:'/app/payments/loose-husk-transporter',
              icon:'chevron-forward-outline',
              color:'warning',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },{
              title:'Hire Provider',
              url:'/app/payments/hire-provider-payment',
              icon:'chevron-forward-outline',
              color:'warning',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },
          ]
        },
        {
          title:'Salary',
          icon:'person-outline',
          color:'secondary',
          moduleName:'salary',
          children:[
            {
              title:'Weekly Wages',
              icon:'ellipse-outline',
              color:'primary',
              moduleName:'salary',
              actionName:['salary-weeklylocalprepay','salary-weeklylocalpostpay','salary-weeklylocalpayment'],
              children:[
                  {
                    title:'Pre-Pay Report',
                    url:'/app/salary/weekly-local-labours/pre-pay-report',
                    icon:'remove-outline',
                    color:'tertiary',
                    moduleName:'salary',
                    actionName:'salary-weeklylocalprepay',
                  },
                  {
                    title:'Post-Pay Report',
                    url:'/app/salary/weekly-local-labours/post-pay-report',
                    icon:'remove-outline',
                    color:'tertiary',
                    moduleName:'salary',
                    actionName:'salary-weeklylocalpostpay',
                  },
                  {
                    title:'Payment',
                    url:'/app/salary/weekly-local-labours',
                    icon:'remove-outline',
                    color:'tertiary',
                    moduleName:'salary',
                    actionName:'salary-weeklylocalpayment',
                  },
                ]
            },
            {
              title:'Weekly OS Inc.',
              icon:'ellipse-outline',
              color:'secondary',
              moduleName:'salary',
              actionName:['salary-weeklyotherprepay','salary-weeklyotherpostpay','salary-weeklyotherpayment'],
              children:[
                  {
                    title:'Pre-Pay Report',
                    url:'/app/salary/weekly-other-labours/pre-pay-report',
                    icon:'remove-outline',
                    color:'tertiary',
                    moduleName:'salary',
                    actionName:'salary-weeklyotherprepay',
                  },
                  {
                    title:'Post-Pay Report',
                    url:'/app/salary/weekly-other-labours/post-pay-report',
                    icon:'remove-outline',
                    color:'tertiary',
                    moduleName:'salary',
                    actionName:'salary-weeklyotherpostpay',
                  },
                  {
                    title:'Payment',
                    url:'/app/salary/weekly-other-labours',
                    icon:'remove-outline',
                    color:'tertiary',
                    moduleName:'salary',
                    actionName:'salary-weeklyotherpayment',
                  },
                ]
            },
            {
              title:'Agent Payment Approval',
              url:'/app/salary/monthly-agents',
              icon:'ellipse-outline',
              color:'danger',
              moduleName:'salary',
              actionName:'salary-agentpaymentapproval',
            },  {
              title:'Agent Monthly Payment',
              icon:'ellipse-outline',
              url:'/app/salary/monthly-agents',
              color:'primary',
              moduleName:'salary',
              actionName:'salary-agentmonthlypayment',
            }
          ]
        },
        {
          title:'Reports',
          icon:'document-text',
          color:'medium',
          moduleName:'reports',
          children:[
            {
              title:'Attendance Report',
              icon:'document-text',
              color:'success',
              moduleName:'reports',
              actionName:['reports-otherstatelabourattendance','reports-locallabourattendance'],
              children:[
                {
                  title:'Other State Labours Report',
                  url:'/app/reports/attendance-other-state-labour',
                  icon:'document-text',
                  color:'warning',
                  moduleName:'reports',
                  actionName:'reports-otherstatelabourattendance',
                },{
                  title:'Local Labours Weekly Report',
                  url:'/app/reports/attendance-weekly-local-labour',
                  icon:'document-text',
                  color:'tertiary',
                  moduleName:'reports',
                  actionName:'reports-locallabourattendance',
                }
              ]
            },
            {
              title:'Day Close Report',
              url:'/app/reports/day-close-report',
              icon:'calendar',
              color:'danger',
              moduleName:'reports',
              actionName:'reports-dayclose',
            },
            {
              title:'Fuel Consumption Report',
              url:'/app/reports/fuel-consumption',
              icon:'chevron-forward-outline',
              color:'tertiary',
              moduleName:'reports',
              actionName:'reports-fuelconsumption',
            },
            {
              title:'Monthly Agent Report',
              url:'/app/reports/monthly-agent-report',
              icon:'chevron-forward-outline',
              color:'tertiary',
              moduleName:'reports',
              actionName:'reports-agentmonthly',
            },
            {
              title:'Ledger Report',
              icon:'document-text',
              color:'success',
              moduleName:'reports',
              actionName:['reports-ledgeragent','reports-ledgerbunk','reports-ledgeremployeeincentive',
            'reports-ledgeremployeesalary','reports-ledgersupplier','reports-ledgercontractteamworkers'],
              children:[
                {
                  title:'Agent Ledger',
                  url:'/app/reports/agent-ledger',
                  icon:'chevron-forward-outline',
                  color:'danger',
                  moduleName:'reports',
                  actionName:'reports-ledgeragent',
                },
                {
                  title:'Bunk Ledger',
                  url:'/app/reports/bunk-leadger',
                  icon:'chevron-forward-outline',
                  color:'tertiary',
                  moduleName:'reports',
                  actionName:'reports-ledgerbunk',
                },
                {
                  title:'Employee Incentive Ledger',
                  url:'/app/reports/employee-incentive-ledger',
                  icon:'chevron-forward-outline',
                  color:'success',
                  moduleName:'reports',
                  actionName:'reports-ledgeremployeeincentive',
                },
                {
                  title:'Employee Salary Ledger',
                  url:'/app/reports/employee-salary-ledger',
                  icon:'chevron-forward-outline',
                  color:'secondary',
                  moduleName:'reports',
                  actionName:'reports-ledgeremployeesalary',
                },{
                  title:'Supplier Ledger',
                  url:'/app/reports/supplier-ledger',
                  icon:'chevron-forward-outline',
                  color:'warning',
                  moduleName:'reports',
                  actionName:'reports-ledgersupplier',
                },
                {
                  title:'Contract Team Workers',
                  url:'/app/reports/contract-team-workers-ledger',
                  icon:'chevron-forward-outline',
                  color:'success',
                  moduleName:'reports',
                  actionName:'reports-ledgercontractteamworkers',
                },
                {
                  title:'Loose Husk Transporter',
                  url:'/app/reports/loose-husk-transporter-ledger',
                  icon:'document-text',
                  color:'primary',
                  moduleName:'underdevelopment',
                  actionName:'underdevelopment-development',
                  },
                  {
                    title:'Hire Provider',
                    url:'/app/reports/hire-provider',
                    icon:'document-text',
                    color:'primary',
                    moduleName:'underdevelopment',
                    actionName:'underdevelopment-development',
                    }
              ]
            },
            {
              title:'Agent Payment Report',
              url:'/app/reports/monthly-agent-report',
              icon:'document-text',
              color:'danger',
              moduleName:'reports',
              actionName:'reports-agentpayment',
            },
            {
              title:'Purchase Report',
              url:'/app/reports/purchase-report',
              icon:'document-text',
              color:'success',
              // moduleName:'reports',
              // actionName:'reports-purchase',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },
            {
              title:'Pith Load',
              url:'/app/reports/pith-load-report',
              icon:'document-text',
              color:'secondary',
              // moduleName:'reports',
              // actionName:'reports-pithload',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },
            {
              title:'Supplier Settlement',
              url:'/app/reports/supplier-settlement-report',
              icon:'document-text',
              color:'warning',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },
            {
              title:'Pith Bag Issue',
              url:'/app/reports/pith-bag-issue',
              // iconURL:'assets/icon/gas-pump-solid.svg',
              icon:'document-text',
              color:'tertiary',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            }
          ]
        },
        {
          title:'Hire Provider',
          icon:'remove-outline',
          color:'danger',
          moduleName:['underdevelopment'],
          // moduleName:['masterbunk','employee','employeeagent','masterownvehicles','mastermachineries',
          // 'masterhusksupplier','transporters','mastercontractteamworkers','yard','masterloosehusktransporters'],
          // isMulti:true,
          children:[
            {
              title:'Hire Entry',
              url:'/app/hire-entry',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },
          ]
        },
        {
          title:'Masters',
          icon:'home',
          color:'success',
          moduleName:['masterbunk','employee','employeeagent','masterownvehicles','mastermachineries',
          'masterhusksupplier','transporters','mastercontractteamworkers','yard','masterloosehusktransporters'],
          isMulti:true,
          children:[
            {
              title:'Bunk Master',
              url:'/app/masters/petrol-bunk',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'masterbunk',
              actionName:'masterbunk-view',
            },
            {
              title:'Employee',
              url:'/app/employees',
              icon:'people-circle',
              color:'secondary',
              moduleName:'employee',
              actionName:'employee-view'
            },
            {
              title:'Agent',
              url:'/app/masters/agents',
              icon:'people',
              color:'success',
              moduleName:'employeeagent',
              actionName:'employeeagent-view'
            },{
              title:'Own Vehicle',
              url:'/app/masters/own-vehicles',
              iconURL:'assets/icon/truck-solid.svg',
              color:'secondary',
              moduleName:'masterownvehicles',
              actionName:'masterownvehicles-view',
            },{
              title:'Machineries',
              url:'/app/masters/machineries',
              icon:'construct',
              color:'primary',
              moduleName:'mastermachineries',
              actionName:'mastermachineries-view',
            },{
              title:'Husk Suppliers',
              url:'/app/masters/husk-suppliers',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'masterhusksupplier',
              actionName:'masterhusksupplier-view',
            }, {
              title:'Bale Transporters',
              url:'/app/masters/bale-transporters',
              icon:'chevron-forward-outline',
              color:'success',
              moduleName:'transporters',
              actionName:'transporters-view'
            }, {
              title:'Contract Team Workers',
              url:'/app/masters/contract-team-workers',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'mastercontractteamworkers',
              actionName:'mastercontractteamworkers-view',
            }, {
              title:'Yard',
              url:'/app/masters/yard',
              icon:'storefront',
              color:'tertiary',
              moduleName:'yard',
              actionName:'yard-view'
            },{
              title:'Loose Husk Transporter',
              url:'/app/masters/loose-husk-transporters',
              iconURL:'assets/icon/truck-moving-solid.svg',
              color:'danger',
              moduleName:'masterloosehusktransporters',
              actionName:'masterloosehusktransporters-view',
            },{
              title:'Pith Stock Yards',
              url:'/app/masters/pith-stock-yards',
              icon:'storefront',
              color:'secondary',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },{
              title:'Hire Provider',
              url:'/app/masters/hire-provider',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            },{
              title:'Hire Routes',
              url:'/app/masters/hire-routes',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            }
          ]
        },
        {
          title:'Settings',
          icon:'settings',
          color:'warning',
          moduleName:'settings',
          children:[
            {
              title:'Salary Incentive',
              url:'/app/settings/salary-incentives',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'settings',
              actionName:"settings-salaryincentiveview"
            },
            {
              title:'Curling',
              url:'/app/settings/curling',
              icon:'chevron-forward-outline',
              color:'medium',
              moduleName:'settings',
              actionName:"settings-curlingview"
            },
            {
              title:'Purchase & Stock',
              url:'/app/settings/purchase',
              icon:'chevron-forward-outline',
              color:'primary',
              moduleName:'settings',
              actionName:"settings-purchaseview"
            },
            {
              title:'F&C Settings',
              url:'/app/settings/fandc-settings',
              icon:'chevron-forward-outline',
              color:'success',
              // moduleName:'settings',
              // actionName:"settings-purchaseview"
            },{
              title:'Bulk Rate Config. Settings',
              url:'/app/masters/husk-suppliers/rate-configuration',
              icon:'chevron-forward-outline',
              color:'danger',
              moduleName:'underdevelopment',
              actionName:'underdevelopment-development',
            }
          ]
        },
        {
          title:'Roles',
          url:'/app/roles',
          icon:'people-circle',
          color:'tertiary',
          moduleName:'roles',
          actionName:'roles-view'
        },
        {
          title:'Users',
          url:'/app/users',
          icon:'people',
          color:'warning',
          moduleName:'users',
          actionName:'users-view'
        }
      ]
    }
  }
  async logOut(){
    // var loading = await this.loadingController.create({
    //   message: 'Requesting...',
    //   spinner: 'bubbles'
    // });
    // await loading.present();
    // if(this.api.isOnline){
    //   this.api.postWtkn('auth/logout')
    //   .subscribe((d : any)=>{
    //     if(d.status){
    //       loading.dismiss();
          this.api.setToken(null);
          this.auth.setLoginStatus(false);
          this.storage.clear();
          this.help.presentToast('Log-out Successfully...');
          this.navController.navigateRoot(['/login']);
    //     }
    //     else{
    //       loading.dismiss();
    //       if(d.statuscode !="LOGI-01102")
    //         this.help.presentToast("Something went wrong. Try again...");
    //     }
    //   },(error) => { console.log(error);
    //     loading.dismiss();
    //     this.help.presentToast('Something went wrong. Try again...',2000);
    //   });
    // }
    // else{
    //   loading.dismiss();
    //   this.help.presentToast('You are offline. Retry.',2000);
    // }
  }
}