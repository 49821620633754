import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from './components/shared.module';
import { AuthService } from './services/auth.service';
import { MenuPageModule } from './template/menu/menu.module';
import { DataTablesModule } from 'angular-datatables';
import {NgxPrintModule} from 'ngx-print';
import { QRCodeModule } from 'angularx-qrcode';
import { CalendarModule } from 'ion2-calendar';
import { NgxPermissionsModule } from 'ngx-permissions';
import {WebcamModule} from 'ngx-webcam';
import { ExportAsModule } from 'ngx-export-as';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LightboxModule } from 'ngx-lightbox';
import { DatePipe } from '@angular/common';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import { RoundPipe } from './pipes/round.pipe';

@NgModule({
  declarations: [AppComponent,
    // RoundPipe
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    SharedModule, HttpClientModule,
    IonicStorageModule.forRoot(),MenuPageModule,DataTablesModule,NgxPrintModule,QRCodeModule,CalendarModule,
    NgxPermissionsModule.forRoot(),
    ExportAsModule,
    WebcamModule,NgxDaterangepickerMd.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LightboxModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },AuthService, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
